<!-- This file is mostly a copy of MyHours.HoursTable.vue.
Some extra functionality is added, and some is removed.
Ideally there should be less code repetition between these two files. -->
<template>
  <!-- The table -->
  <el-table
      ref="mngHoursTableRef"
      :data="displayableHours"
      v-loading="loading.table"
      border stripe
      max-height="60vh"
      :summary-method="tableSummary"
      @row-click="rowClicked"
      style="min-width: 550px"
      show-summary>  <!-- 710px for no double-lines in header -->

    <!-- The expandable row -->
    <el-table-column type="expand" width="55px">
      <template #default="scope">
        <div class="expandable-row-div">

          <!-- Title and delete-button -->
          <el-row justify="space-between" align="middle">
            <h3>Flere detaljer</h3>
            <div>
            </div>
          </el-row>

          <!-- Extra details form. Using form for easy layout/styling -->
          <el-form id="expandable-row-form" label-width="auto" label-position="left">
            <el-form-item label="Starttid">{{ expandableRowTimeFormatter(scope.row.start_time) }}</el-form-item>
            <el-form-item label="Sluttid">{{ expandableRowTimeFormatter(scope.row.end_time) }}</el-form-item>
            <!--<el-form-item label="Selskap">{{ scope.row.project.department.name }}</el-form-item>-->
            <el-form-item label="Ansatt">{{ scope.row.emp_email }}</el-form-item>
            <el-form-item label="Beskrivelse">{{ scope.row.description || '-' }}</el-form-item>
            <el-form-item label="Lagt til">{{ expandableRowTimeFormatter(scope.row.logged_time, true) }}</el-form-item>
            <!--            <el-form-item label="Lokasjon">{{ "TODO" }}</el-form-item>-->
          </el-form>
        </div>
      </template>
    </el-table-column>

    <!-- The table columns -->
    <el-table-column prop="employee.name" label="Ansatt" sortable :min-width="20" :filters="filters.employees"
                     :filter-method="filterTable"/>
    <el-table-column prop="project.department.name" label="Selskap" sortable :min-width="20"
                     :filters="filters.departments" :filter-method="filterTable"/>
    <el-table-column prop="project.name" label="Prosjekt" sortable :min-width="20" :filters="filters.projects"
                     :filter-method="filterTable"/>
    <el-table-column prop="startDay" label="Dag" :min-width="15" :formatter="tableRowFormatter"/>
    <el-table-column prop="startDate" label="Dato" :min-width="15" :formatter="tableRowFormatter"/>
    <el-table-column prop="startTime" label="Starttid" :min-width="10" :formatter="tableRowFormatter"/>
    <el-table-column prop="endTime" label="Sluttid" :min-width="10" :formatter="tableRowFormatter"/>
    <el-table-column prop="duration" label="Varighet" :min-width="10" :formatter="tableRowFormatter"/>
  </el-table>
</template>

<script setup lang="js">
import * as dayjs from 'dayjs'
import {useManagerDepartmentStore} from '@/store/manager/managerDepartmentStore'
import {useManagerHourStore} from '@/store/manager/managerHourStore'
import {computed, onMounted, reactive, ref, watch} from "vue";

const mngDepsStore = useManagerDepartmentStore()
const mngHourStore = useManagerHourStore()

const mngHoursTableRef = ref(null)

const filters = reactive({
  employees: [],
  departments: [],
  projects: []
})

const loading = reactive({
  table: true
})

// eslint-disable-next-line no-undef
const props = defineProps({
  tableRange: {
    type: Date,
    required: true
  },
  employee: {
    type: Object,
    required: false
  }
})

const displayableHours = computed(() => {
  if (props.employee) {
    return mngHourStore.hours.filter(h => h.employee.id === props.employee.id)
  } else {
    return mngHourStore.hours
  }
})

const tableRange = computed(() => {
  return props.tableRange
})
const tableRangeType = ref(null)

const loadTable = async () => {
  loading.table = true
  const [start, end] = calculateRange()
  await mngHourStore.getHoursAsManager(start, end)
  loading.table = false
}

const calculateRange = () => {
  let start = null
  let end = null
  const day = tableRange.value.getDay()

  switch (tableRangeType.value) {

    case 'date':
      start = tableRange.value
      end = new Date(start)
      end.setDate(start.getDate() + 1)
      break
    case 'week':
      start = tableRange.value
      start.setDate(start.getDate() - day + (day === 0 ? -6 : 1))
      end = new Date(start)
      end.setDate(start.getDate() + 7)
      break
    case 'month':
      start = tableRange.value
      start.setDate(1)
      end = new Date(start)
      end.setMonth(start.getMonth() + 1)
      break
    default:
      start = tableRange.value
      start.setDate(1)
      end = new Date(start)
      end.setMonth(start.getMonth() + 1)
  }

  return [start.valueOf(), end.valueOf()]
}

const UTCISOToLocalDate = (UTCString) => {
  const date = new Date(UTCString)
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
}

const tableRowFormatter = (row, column) => {
  switch (column.property) {
    case 'startDay': {
      const localDate = UTCISOToLocalDate(row['start_time'])
      return dayjs.weekdays()[localDate.getDay()]
    }
    case 'startDate': {
      const localDate = UTCISOToLocalDate(row['start_time'])
      return localDate.getDate() + '. ' + dayjs.monthsShort()[localDate.getMonth()] + ' ' + localDate.getFullYear()
    }
    case 'startTime': {
      const localDate = UTCISOToLocalDate(row['start_time'])
      return localDate.toTimeString().substring(0, 5)
    }
    case 'endTime': {
      const localDate = UTCISOToLocalDate(row['end_time'])
      if (row['end_time'] === null) {
        return "-"
      } else {
        return localDate.toTimeString().substring(0, 5)
      }
    }
    case 'duration': {
      const localDate1 = UTCISOToLocalDate(row['start_time'])
      const localDate2 = UTCISOToLocalDate(row['end_time'])
      if (row['end_time'] === null) {
        return "-"
      }
      let timeDelta = (localDate2.valueOf() - localDate1.valueOf()) / 1000
      const lunchStart = UTCISOToLocalDate(row['start_time']);
      lunchStart.setHours(12, 0, 0);
      const lunchEnd = UTCISOToLocalDate(row['end_time']);
      lunchEnd.setHours(12, 30, 0);
      if (timeDelta >= 19800 && !row['project'].has_paid_lunch) {
        timeDelta = timeDelta - 1800
      } else if (localDate1 <= lunchStart && localDate2 >= lunchEnd && !row['project'].has_paid_lunch) {
        timeDelta -= 1800
      }
      const timeDeltaHours = Math.floor(timeDelta / 3600)
      const timeDeltaMinutes = Math.ceil(timeDelta / 60) % 60
      return timeDeltaHours + 't ' + timeDeltaMinutes + 'm'
    }
    default: {
      return "-"
    }
  }
}

const expandableRowTimeFormatter = (timeString, withSeconds = null) => {
  const localDate = UTCISOToLocalDate(timeString)
  const day = dayjs.weekdays()[localDate.getDay()]
  const date = localDate.getDate() + '. ' + dayjs.monthsShort()[localDate.getMonth()] + ' ' + localDate.getFullYear()
  const time = withSeconds ? localDate.toTimeString().substring(0, 8) : localDate.toTimeString().substring(0, 5)
  return day + ", " + date + " kl. " + time
}

const rowClicked = (row) => {
  mngHoursTableRef.value.toggleRowExpansion(row)
}

const tableSummary = ({data}) => {
  const sums = []
  sums[0] = "Sum"
  const lastColumnIndex = 8  // Update when changing amount of table columns
  let timeDeltaTotal = 0
  if (!data) return sums

  data.forEach((row) => {
    if (row['end_time'] !== null) {
      const localDate1 = UTCISOToLocalDate(row['start_time'])
      const localDate2 = UTCISOToLocalDate(row['end_time'])
      let timeDelta = (localDate2.valueOf() - localDate1.valueOf()) / 1000
      const lunchStart = UTCISOToLocalDate(row['start_time']);
      lunchStart.setHours(12, 0, 0);
      const lunchEnd = UTCISOToLocalDate(row['end_time']);
      lunchEnd.setHours(12, 30, 0);
      if (timeDelta >= 19800 && !row['project'].has_paid_lunch) {
        timeDelta = timeDelta - 1800
      } else if (localDate1 <= lunchStart && localDate2 >= lunchEnd && !row['project'].has_paid_lunch) {
        timeDelta -= 1800
      }
      timeDeltaTotal += timeDelta
    }
  })

  const timeDeltaHours = Math.floor(timeDeltaTotal / 3600)
  const timeDeltaMinutes = Math.ceil(timeDeltaTotal / 60) % 60
  sums[lastColumnIndex] = timeDeltaHours + 't ' + timeDeltaMinutes + 'm'
  return sums
}

const filterTable = (value, row, column) => {
  let property = column['property']
  let compared = row[property]
  switch (property) {
    case 'employee.name':
      compared = row.emp_email
      break
    case 'project.department.name':
      compared = row.project.department.id
      break
    case 'project.name':
      compared = row.project.id
      break
  }
  return compared === value
}

const createTableFilters = async () => {
  await mngDepsStore.getDepartmentsAsManager()
  mngDepsStore.departments.forEach((dep) => {
    filters.departments.push({text: dep.name, value: dep.id})
    dep.projects.forEach((proj) => {
      filters.projects.push({text: proj.name, value: proj.id})
    })
    dep.employee_positions.forEach((empPos) => {
      const alreadyExists = filters.employees.some((emp) => emp.value === empPos.emp_email)
      if (!alreadyExists) filters.employees.push({text: empPos.employee.name, value: empPos.emp_email})
    })
  })
  filters.employees.sort((a, b) => a.text.localeCompare(b.text))
}

watch(() => props.tableRange, () => {
  loadTable();
})

onMounted(async () => {
  tableRange.value.setHours(0, 0, 0, 0)
  await Promise.all([loadTable(), createTableFilters()])
})

</script>

<style scoped>
#expandable-row-form .el-form-item {
  margin-bottom: 5px;
}
</style>
<style>
.expandable-row-div {
  width: 88%;
  float: right;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 15px
}
</style>